.p-toast {
    .p-toast-message-icon {
      display: none;
    }
  
    .p-toast-summary {
      font-size: 15px;
    }
  
    .p-toast-detail {
      font-size: 14px;
    }
}