/* Labels */
.label {
    display: inline-block;
    font-size: 10px;

    &.label-warning {
      color: #fff;
      background-color: #ffa800;
    }

    &.label-danger {
      color: #fff;
      background-color: #f64e60;
    }

    &.label-dark {
      color: #fff;
      background-color: #181c32;
    }

    &.label-info {
      color: #fff;
      background-color: #8950fc;
    }

    &.label-primary {
      color: #fff;
      background-color: #3699ff;
    }

    &.label-success {
      color: #fff;
      background-color: #1bc5bd;
    }

    &.label-rounded {
      border-radius: 5px;
    }

    &.label-sm {
      font-size: 9px;
    }

    &.label-lg {
      font-size: 11px;
    }

    &.label-xl {
      font-size: 13px;
    }
  }