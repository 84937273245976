/* FONT SIZES */
.font-size-sm {
    font-size: 12px;
}
.font-size-md {
    font-size: 13px;
}
.font-size-lg {
    font-size: 14px;
}
.font-size-xl {
    font-size: 15px;
}