/* COLORS */
.text-dark-75 {
    color: #464e5f;
}
.text-muted {
    color: #b5b5c3;
}
.text-primary {
    color: #3699ff;
}
.text-dark {
    color: #212121;
}
.primary-red {
    color: #f64e60;
}
.primary-green {
    color: #1bc5bd;
}
.primary-yellow {
    color: #ffa800;
}