/* BOLDS */
.font-weight-boldest {
    font-weight: 700;
}
.font-weight-bolder {
    font-weight: 600;
}
.font-weight-bold {
    font-weight: 500;
}
.font-weight-bold-400 {
    font-weight: 400;
}
.font-weight-thin {
    font-weight: 300;
}
.font-weight-thinner {
    font-weight: 200;
}
.font-weight-thinnest {
    font-weight: 100;
}